import React from "react";
import logsvg from "./logo.svg";

export type LogoProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

export const Logo: React.FC<Omit<LogoProps, "src">> = (props) => {
  return <img alt={process.env.REACT_APP_TITLE} {...props} src={logsvg} />;
};

export default Logo;
