import React from "react";
import { BrowserRouter } from "react-router-dom";
import { RoutesMatch } from "./Routes";

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <RoutesMatch />
    </BrowserRouter>
  );
};

export default App;
