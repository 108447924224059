import React, { useMemo } from "react";
import styled from "styled-components/macro";
import { Outlet } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import ToTop from "./ToTop";

const Wrapper = styled.div`
  padding-top: 60px;
`;

const Body = styled.main`
  max-width: 750px;
  margin: 0 auto;
`;

const Layout: React.FC = () => {
  const bodyMinHeight = useMemo(() => {
    return window.innerHeight - 200 - 62;
  }, []);

  return (
    <Wrapper>
      <Header />
      <Body style={{ minHeight: bodyMinHeight }}>
        <Outlet />
      </Body>
      <Footer />
      <ToTop />
    </Wrapper>
  );
};

export default Layout;
