import React from "react";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  padding: 14px 0;
  background: #000;
  line-height: 26px;
  border-top: 2px solid #353a41;
`;

const CopyrightText = styled.div`
  color: #989898;
  text-align: center;
  font-size: 12px;
  padding: 0 10px;
`;

const CopyrightWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const CopyrightLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #989898;
  text-decoration: none;

  &:hover {
    color: #d78921;
  }
`;

const NormalLink = styled.a`
  color: #989898;
  text-decoration: none;

  &:hover {
    color: #d78921;
  }
`;

export const Container = styled.div`
  width: 1190px;
  margin: 0 auto;
`;

const CopyrightImage = styled.img`
  width: 20px;
  margin-right: 5px;
`;

export const Copyright = () => {
  return (
    <Wrapper>
      <Container>
        <CopyrightText>
          Copyright © 2012-2022 健医信息科技（上海）股份有限公司
        </CopyrightText>
        <CopyrightWrapper>
          <CopyrightText>
            <CopyrightLink
              target="_blank"
              href={process.env.REACT_APP_LINK_HGWAB}
            >
              <CopyrightImage src={require("./assets/beian2016.png")} />
              <span>沪公网安备 31011002000714号</span>
            </CopyrightLink>
          </CopyrightText>
          <CopyrightText>
            <CopyrightLink
              target="_blank"
              href={process.env.REACT_APP_LINK_ICP}
            >
              <span>【沪ICP备14014796号】</span>
            </CopyrightLink>
          </CopyrightText>
        </CopyrightWrapper>
      </Container>
    </Wrapper>
  );
};
