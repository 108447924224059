import React, { useMemo } from "react";
import styled from "styled-components/macro";
import { NavLink, Link, useLocation, NavLinkProps } from "react-router-dom";
import Logo from "../../components/logo";

const Wrapper = styled.header`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: #000000;
  box-shadow: 3.5px 3.5px 9.6px 0.4px rgba(27, 27, 27, 0.45);
  z-index: 100;
  min-width: 1190px;
`;

export const Container = styled.div`
  width: 1190px;
  margin: 0 auto;
  display: flex;
  height: 62px;
  justify-content: space-between;
  align-items: center;
`;

const LogoLink = styled(Link)`
  display: block;
  height: 62px;
  overflow: hidden;
`;

const LogoImage = styled(Logo)`
  display: block;
  height: 100px;
  margin-top: -28px;
`;

const Navbar = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
`;

const NavbarLink = styled(NavLink)`
  width: 84px;
  height: 62px;
  font-size: 14px;
  color: #ffffff;
  text-decoration: none;
  padding: 25px 0 0;
  text-align: center;

  &:hover {
    color: #d78921;
  }

  &:not(:first-child) {
    margin-left: 32px;
  }

  &.active {
    color: #d78921;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 84px;
  height: 6px;
  background-color: #d78921;
  transition: left 0.3s ease-out;
`;

const NavbarList: (NavLinkProps & { key: string })[] = [
  { key: "home", to: "/", children: "首页" },
  { key: "product", to: "/product", children: "产品服务" },
  { key: "partner", to: "/partner", children: "合作伙伴" },
  { key: "about", to: "/about", children: "关于健医" },
];

export const Header = React.forwardRef<any>((props, ref) => {
  const { pathname } = useLocation();

  const left = useMemo(() => {
    if (pathname === "/") {
      return 0;
    }
    const index = NavbarList.findIndex((props) => {
      if (props.to === "/") {
        return false;
      }
      return pathname.startsWith(props.to as string);
    });
    if (!~index) {
      return 0;
    }
    return (84 + 32) * index;
  }, [pathname]);

  return (
    <Wrapper>
      <Container>
        <LogoLink to="/">
          <LogoImage />
        </LogoLink>

        <Navbar>
          {NavbarList.map((props) => (
            <NavbarLink {...props} />
          ))}
          <Placeholder style={{ left }} />
        </Navbar>
      </Container>
    </Wrapper>
  );
});

export default Header;
