import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import Loading from "../components/loading";
import NotFound from "../components/notfound";
import Layout from "./fragment/Layout";

const Home = lazy(() => import("./home"));
const About = lazy(() => import("./about"));
const Partner = lazy(() => import("./partner"));
const Product = lazy(() => import("./product"));

const withSuspense = (element: React.ReactNode) => {
  return <Suspense fallback={<Loading />}>{element}</Suspense>;
};

export const Desktop: React.FC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={withSuspense(<Home />)} />
        <Route path="about" element={withSuspense(<About />)} />
        <Route path="partner" element={withSuspense(<Partner />)} />
        <Route path="product" element={withSuspense(<Product />)} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};
