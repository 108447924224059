import React, { useCallback, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, Link } from "react-router-dom";
import { hiDPI } from "polished";
import { useBoolean } from "../../../components/hooks";
import { useTransition } from "../../../components/animation";

const Wrapper = styled.header`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: #010101;
  box-shadow: 0px 4px 20px 0px rgba(1, 1, 1, 0.4);
  z-index: 100;
`;

export const Container = styled.div`
  margin: 0 20px;
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
`;

const LogoLink = styled(Link)`
  display: block;
  overflow: hidden;
`;

const LogoImage = styled.div`
  display: block;
  width: 78px;
  height: 24px;
  background-position: center;
  background-size: contain;
  background-image: url(${require("./assets/logo.png")});

  ${hiDPI(1.5)} {
    background-image: url(${require("./assets/logo@2x.png")});
  }

  ${hiDPI(2.5)} {
    background-image: url(${require("./assets/logo@3x.png")});
  }
`;

const MenuIcon = styled.i`
  display: inline-block;
  width: 22px;
  height: 20px;
  background-position: center;
  background-size: contain;
  background-image: url(${require("./assets/menu.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("./assets/menu@2x.png")});
  }
  ${hiDPI(2.5)} {
    background-image: url(${require("./assets/menu@3x.png")});
  }
`;

const Menu = styled.div`
  width: 60px;
  height: 60px;
  border: none;
  margin-right: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Navbar = styled.nav`
  position: absolute;
  top: 100%;
  right: 0;
  width: 168px;
  background-color: #252525;
  padding-bottom: 20px;
`;

const NavbarLink = styled(NavLink)`
  display: block;
  height: 54px;
  font-size: 16px;
  color: #ffffff;
  border-left: 2px solid transparent;
  text-decoration: none;
  padding: 22px 14px 0;

  &.active {
    color: #d78921;
    border-left-color: #d78921;
  }
`;

const Header = React.forwardRef<any>((props, ref) => {
  const [visible, toggle] = useBoolean();
  const [show, state] = useTransition(visible, {
    from: { transform: "translate(225px, 0)" },
    enter: { transform: "translate(0px, 0)" },
  });

  const onToggle = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      toggle();
    },
    [toggle]
  );

  useEffect(() => {
    const handler = () => {
      toggle(false);
    };

    window.addEventListener("click", handler);

    return () => {
      window.removeEventListener("click", handler);
    };
  }, [toggle]);

  return (
    <Wrapper>
      <Container>
        <LogoLink to="/m" title={process.env.REACT_APP_TITLE}>
          <LogoImage />
        </LogoLink>

        <Menu onClick={onToggle}>
          <MenuIcon />
        </Menu>
      </Container>

      {show && (
        <Navbar style={state}>
          <NavbarLink to="/m" end>
            首页
          </NavbarLink>
          <NavbarLink to="/m/product">产品服务</NavbarLink>
          <NavbarLink to="/m/partner">合作伙伴</NavbarLink>
          <NavbarLink to="/m/about">关于健医</NavbarLink>
        </Navbar>
      )}
    </Wrapper>
  );
});

export default Header;
