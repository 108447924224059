import React from "react";
import styled from "styled-components/macro";

import { Copyright } from "./copyright";
import { Contact } from "./contact";
import { Nav } from "./nav";

const Wrapper = styled.footer`
  background-color: #000;
  min-width: 1190px;
`;

export const Container = styled.div`
  width: 1190px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;

export const Disclaimer = styled.div`
  width: 1190px;
  margin: 0 auto;
  color: #989898;
  font-size: 14px;
  text-align: right;
  position: relative;
  top: -35px;
  height: 0px;
`;

const Footer = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <Wrapper>
      <Container>
        <Contact />
        <Nav />
      </Container>
      <Disclaimer>如有侵权内容请与我们联系删除</Disclaimer>
      <Copyright />
    </Wrapper>
  );
});

export default Footer;
