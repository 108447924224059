import React, { useEffect, useRef } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router";

import { Desktop } from "./desktop";
import { Mobile } from "./mobile";

const isMobile = (() => {
  const ua = navigator.userAgent.toLowerCase();
  return /mobile|android|iphone|ipad|phone/i.test(ua);
})();

export const RoutesMatch: React.FC = () => {
  const { pathname } = useLocation();
  const prePathnameRef = useRef(pathname);
  const isMobileUrl = pathname.startsWith("/m");

  useEffect(() => {
    if (pathname !== prePathnameRef.current) {
      window.scrollTo({ top: 0 });
    }
  }, [pathname]);

  if (isMobile && !isMobileUrl) {
    const url = pathname === "/" ? "" : pathname;
    return <Navigate to={`/m${url}`} replace />;
  } else if (!isMobile && isMobileUrl) {
    const url = pathname.substring(2);
    return <Navigate to={url} replace />;
  }

  return (
    <Routes>
      <Route path="*" element={<Desktop />} />
      <Route path="/m/*" element={<Mobile />} />
    </Routes>
  );
};
