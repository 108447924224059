import styled from "styled-components/macro";

import { DefaultLoading } from "../image-view";

export const Loading = styled.div`
  width: 50px;
  margin: 0 auto;
  height: 50px;
  background-position: center center;
  background-size: contain;
  background-image: url(${DefaultLoading});
  background-repeat: no-repeat;
`;

export default Loading;
