import React, { useMemo } from "react";
import styled from "styled-components/macro";
import { Outlet } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

const Wrapper = styled.div`
  padding-top: 62px;
`;

const Body = styled.main``;

const Layout: React.FC = () => {
  const bodyMinHeight = useMemo(() => {
    return window.innerHeight - 365 - 62;
  }, []);

  return (
    <Wrapper>
      <Header />
      <Body style={{ minHeight: bodyMinHeight }}>
        <Outlet />
      </Body>
      <Footer />
    </Wrapper>
  );
};

export default Layout;
