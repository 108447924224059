import React, { useCallback, useEffect } from "react";
import styled from "styled-components/macro";
import { hiDPI } from "polished";
import { useBoolean } from "../../../components/hooks";

const Wrapper = styled.div`
  position: fixed;
  bottom: 120px;
  right: 5px;
`;

const Icon = styled.div`
  width: 41px;
  height: 66px;
  margin: 0 auto 6px;
  background-position: center;
  background-size: contain;
  background-image: url(${require("./assets/top.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("./assets/top@2x.png")});
  }
  ${hiDPI(2.5)} {
    background-image: url(${require("./assets/top@3x.png")});
  }
`;

const Text = styled.div`
  font-size: 10px;
`;

export const ToTop = () => {
  const [visible, toggle] = useBoolean();

  useEffect(() => {
    const handler = () => {
      const visible = window.scrollY > window.innerHeight;
      toggle(visible);
    };

    window.addEventListener("scroll", handler, {
      capture: false,
      passive: true,
    });

    return () => window.removeEventListener("scroll", handler);
  }, [toggle]);

  const onTop = useCallback(() => {
    window.scrollTo({ top: 0 });
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <Wrapper onClick={onTop}>
      <Icon />
      <Text>回到顶部</Text>
    </Wrapper>
  );
};

export default ToTop;
