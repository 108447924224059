import React from "react";
import styled from "styled-components/macro";

import Logo from "../../../components/logo";

const Wrapper = styled.div`
  padding: 56px 0 10px;
`;

const Group = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const LogoImageBox = styled.div`
  width: 157px;
  height: 47px;
`;

const LogoImage = styled(Logo)`
  position: relative;
  top: -50px;
  width: 162px;
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 32px;
  color: #989898;
`;

const Placeholder = styled.div`
  height: 12px;
`;

const Phone = styled.div`
  font-size: 30px;
  color: #d78921;
  margin-left: 32px;
`;

const TextLink = styled.a`
  margin-right: 10px;
  color: #989898;
  text-decoration: none;

  &:hover {
    color: #d78921;
  }
`;

export const Contact = () => {
  return (
    <Wrapper>
      <Group>
        <LogoImageBox>
          <LogoImage />
        </LogoImageBox>
        <Phone>400-111-5289</Phone>
      </Group>
      <Text>公司邮箱：service@jianyi.tech</Text>
      <Text>公司地址：上海市杨浦区长阳路1687号长阳谷创意产业园1号楼4层</Text>
      <Placeholder />
      <Text>
        业务入口：
        <TextLink target="_blank" href={process.env.REACT_APP_LINK_JEK}>
          健e卡用户入口
        </TextLink>
        <TextLink target="_blank" href={process.env.REACT_APP_LINK_BXGS}>
          保险公司入口
        </TextLink>
        <TextLink target="_blank" href={process.env.REACT_APP_LINK_YLJG}>
          医疗机构入口
        </TextLink>
      </Text>
    </Wrapper>
  );
};
