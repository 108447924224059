import React, { useMemo, useState, useCallback } from "react";
import { useInView } from "react-intersection-observer";
import { isString, isArray } from "../utils/types";

export type ImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  tag?: string;
  srcs?: string[];
  lazy?: boolean;
};

export const DefaultLoading = require("./spinner.svg").default;

let dpr = window.devicePixelRatio || 1;
if (dpr >= 1.5) {
  dpr = 2;
} else if (dpr >= 2.5) {
  dpr = 3;
}

const getImageWithDevicePixelRatio = (srcs?: string[]) => {
  if (!isArray(srcs) || !srcs.length) {
    return "";
  }
  for (const s of srcs) {
    if (s.search(`@${dpr}x.`) > -1) {
      return s;
    }
  }
  return srcs[0];
};

const ImageViewMap: { [url: string]: boolean } = {};

export const ImageView: React.FC<ImageProps> = ({
  src,
  srcs,
  lazy = true,
  ...props
}) => {
  const lastSrc = useMemo(() => {
    return isString(src) ? src : getImageWithDevicePixelRatio(srcs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, srcs?.join(",")]);

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const show = lazy ? inView || ImageViewMap[lastSrc] : true;
  if (!ImageViewMap[lastSrc]) {
    ImageViewMap[lastSrc] = inView;
  }

  const [opacity, set] = useState(lazy && !ImageViewMap[lastSrc] ? 0 : 1);
  const onLoad = useCallback(() => set(1), []);

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      ref={ref}
      {...props}
      src={show ? lastSrc : ""}
      data-src={lastSrc}
      onLoad={onLoad}
      style={{
        minHeight: 1,
        minWidth: 1,
        ...props.style,
        opacity,
        transition: "opacity 0.3s",
      }}
    />
  );
};

export default ImageView;
