import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components/macro";

const Content = styled.div`
  padding: 80px;
  text-align: center;
`;

const Title = styled.h2`
  color: #666;
  font-size: 38px;
`;

const Text = styled.p`
  font-size: 32px;
  color: #666;
`;

export const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <Content>
        <Title>404</Title>
        <Text>PAGE NOT FOUND</Text>
      </Content>
    </>
  );
};

export default NotFound;
