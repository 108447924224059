import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useScrollToAnchor = () => {
  const location = useLocation();
  useEffect(() => {
    if (!location.hash) {
      return;
    }
    const id = location.hash.substr(1);
    setTimeout(() => {
      const dom = document.getElementById(id);
      if (dom) {
        window.scrollTo({ top: dom.offsetTop - 70 });
      }
    }, 100);
  }, [location]);
};
