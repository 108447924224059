import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  padding-top: 76px;
  display: flex;
  justify-content: flex-start;
`;

const Group = styled.div`
  &:not(:last-child) {
    margin-right: 50px;
  }
`;

const GroupTitle = styled.h2`
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 20px;
`;

const Item = styled.div`
  display: block;
`;

const ItemLink = styled(Link)`
  line-height: 26px;
  color: #989898;
  font-size: 14px;
  text-decoration: none;

  &:hover {
    color: #d78921;
  }
`;

export const Nav = () => {
  return (
    <Wrapper>
      <Group>
        <GroupTitle>走进健医</GroupTitle>
        <Item>
          <ItemLink to="/#ProductService">产品服务</ItemLink>
        </Item>
        <Item>
          <ItemLink to="/#CoreMedical">核心医疗资源</ItemLink>
        </Item>
        <Item>
          <ItemLink to="/#CoreDoctor">核心医生资源</ItemLink>
        </Item>
        <Item>
          <ItemLink to="/#CoreTechnology">核心科技</ItemLink>
        </Item>
        <Item>
          {/* <ItemLink to="/#InsurancePartners">签约保险公司</ItemLink> */}
        </Item>
      </Group>
      <Group>
        <GroupTitle>产品服务</GroupTitle>
        <Item>
          <ItemLink to="/product">产品服务</ItemLink>
        </Item>
      </Group>
      <Group>
        <GroupTitle>合作伙伴</GroupTitle>
        <Item>
          <ItemLink to="/partner">合作伙伴</ItemLink>
        </Item>
      </Group>
      <Group>
        <GroupTitle>关于健医</GroupTitle>
        <Item>
          <ItemLink to="/about#AboutUs">关于健医</ItemLink>
        </Item>
        <Item>
          <ItemLink to="/about#Honor">荣誉资质</ItemLink>
        </Item>
      </Group>
    </Wrapper>
  );
};
