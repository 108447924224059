import React from "react";
import styled from "styled-components/macro";

const Wrapper = styled.footer`
  background: #000;
`;

const Contact = styled.div`
  padding: 24px 0;
`;

const SiteInfo = styled.div`
  padding: 16px 0;
  background-color: #252525;
  color: #a9a9a9;
`;

export const Container = styled.div`
  margin: 0 18px;
`;

const Text = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: #f8f8f8;
  text-align: center;
`;

const Link = styled.a`
  color: #f8f8f8;
  text-decoration: none;
`;

const Footer = () => {
  return (
    <Wrapper>
      <Contact>
        <Container>
          <Text>保险公司合作：余女士 021-62563889-6040</Text>
          <Text>医疗机构合作：谢先生 021-62563889-6020</Text>
          <Text>公司邮箱：service@jianyi.tech</Text>
          <Text>
            公司地址：上海市杨浦区长阳路1687号长阳谷创意产业园1号楼4层
          </Text>
          <Text>
            如有侵权内容请与我们联系删除
          </Text>
        </Container>
      </Contact>

      <SiteInfo>
        <Container>
          <Text>Copyright © 2012-2022 健医信息科技（上海）股份有限公司</Text>
          <Text>
            <Link target="_blank" href={process.env.REACT_APP_LINK_HGWAB}>
              沪公网安备 31011002000714号
            </Link>
            <Link target="_blank" href={process.env.REACT_APP_LINK_ICP}>
              【沪ICP备14014796号】
            </Link>
          </Text>
        </Container>
      </SiteInfo>
    </Wrapper>
  );
};

export default Footer;
